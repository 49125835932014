@if (vm(); as vm) {
  @if (vm.shouldDisplayErrors) {
    @for (errorDirective of vm.errorsToDisplay.errors; track $index) {
      <div [class]="errorClasses">
        <ng-container
          *ngTemplateOutlet="
            errorDirective!.templateRef;
            context: {
              $implicit: label(),
              error: vm.control.errors![errorDirective.type()]
            }
          "
        />
      </div>
    }
    @for (error of vm.errorsToDisplay.fallbackErrors; track $index) {
      <div [class]="errorClasses">
        <ng-container
          *ngTemplateOutlet="
            vm.errorsToDisplay.fallback!.templateRef;
            context: {
              $implicit: label(),
              type: error.type,
              error: error.value
            }
          "
        />
      </div>
    }
  }
}
